.pricingContainer {
    width: 100%;
    height: 100vh;
    margin-top: 60px;
    padding-top: 5%;
}

.pricingContainer .infoContainer {
    width: 20%;
    height: 52%;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 5rem;
    border-radius: 1.2rem;
    box-shadow:
        0 4px 10px rgba(255, 255, 255, 0.2),
        /* White soft glow */
        0 0 20px rgba(255, 255, 255, 0.1);
    /* White outer shadow */
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.pricingContainer .infoContainer .priceTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.pricingContainer .infoContainer .priceTextContainer h3 {
    font-size: 46px;
    color: #fff;
    letter-spacing: 0.2rem;
    margin: 0;
}

.pricingContainer .infoContainer .priceTextContainer h2 {
    font-family: 'FuturaNowLight', sans-serif;
    font-size: 16px;
    margin-top: 2.5rem;
    letter-spacing: 0.1rem;
}

.pricingContainer .infoContainer .buttonText {
    font-family: 'FuturaNowLight', sans-serif;
    font-size: 1.2rem;
    color: #407ee2;
    margin: 0;
    cursor: pointer;
    margin-top: 1rem;
}

.pricingContainer .infoContainer .line {
    width: 82%;
    height: 0.1rem;
    background-color: rgba(255, 255, 255, 0.1);
    margin-top: 3rem;
}

.featuresContainer {
    width: 82%;
    margin-top: 1rem;
}

.featuresContainer h3 {
    font-size: 18px;
    text-align: left;
    color: rgba(255, 255, 255, 0.7)
}

@media screen and (max-width:1000px) {
    .pricingContainer {
        height: fit-content;
        padding: 4rem 0;

    }

    .pricingContainer span {
        font-size: 2.4rem;
        width: 90%;
        line-height: 3rem;
    }

    .pricingContainer h3 {
        color: rgba(255, 255, 255, 0.3);
        font-size: 1rem;
        width: 100%;
        line-height: 2rem;
        margin-top: 2rem;
    }

    .pricingContainer .infoContainer {
        width: 90%;
    }

    .pricingContainer .infoContainer .priceTextContainer h3 {
        font-size: 36px;
    }

    .pricingContainer .infoContainer .priceTextContainer h2 {
        margin-top: 1.5rem;
        letter-spacing: 0.1rem;
        color: rgba(255, 255, 255, 0.3);
    }

    .pricingContainer .infoContainer .buttonText {
        font-size: 1rem;
    }

    .pricingContainer .infoContainer .line {
        margin-top: 2rem;
    }
    .featuresContainer{
        margin-top: 0;
    }
}