.navBarContainer {
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1rem;
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: transparent;
    backdrop-filter: blur(10px);
    overflow: hidden;
    width: 100%;
}

.navBarContainer .navBarlinksContainer {
    height: 100%;
    min-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0 0.4rem;
}

.navBarContainer .navBarlinksContainer h3 {
    margin: 0 2rem;
    font-size: 18px;
    cursor: pointer;
    color: #f9f6eecc;
    transition: 0.3s ease;
    font-family: 'FuturaNowLight', 'sans-serif';
}

.navBarContainer div h3 {
    font-size: 18px;
    cursor: pointer;
    color: #f9f6eecc;
    transition: 0.3s ease;
    font-family: 'FuturaNowLight', 'sans-serif';
    margin-left: 0.3rem;
}
.navBarContainer div h3:hover{
    color:#fff;
    text-decoration: underline;
}

.navBarContainer .navBarlinksContainer h3:hover {
    color: #fff;
}

@media screen and (max-width:1000px) {
    .navBarContainer{
        justify-content: space-between;
        width: 100%;
    }
}