.section3Container {
    width: 100%;
    height: 80vh;
    margin-bottom: 4rem;
}

.section3Container span {
    font-weight: 600;
    font-size: 54px;
    filter: contrast(21);
    height: 8rem;
}
.section3Container h3{
    color:rgba(255,255,255,0.3);
}

.reflected_text::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    right: 0;
    transform-origin: bottom;
    transform: rotateX(180deg);
    line-height: 1.2em;
    background-image: linear-gradient(0deg, #ffffff 0, transparent 95%);
    -webkit-background-clip: text;
    color: transparent;
    opacity: 0.3;
}

.section3Container .imageContainer {
    width: 25%;
    height: 40vh;
    margin-top: 4rem;
    margin-left: 50%;
    transform: translateX(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../assets/images/chart.png');
    cursor: pointer;
    border-radius: 50%;
    animation: rotate360 60s linear infinite;
}

/* adding animation */
@keyframes rotate360 {
    0% {
        transform: translateX(-50%) rotate(0deg);
    }

    100% {
        transform: translateX(-50%) rotate(360deg);
    }
}

@media screen and (max-width:1000px) {
    .section3Container {
        height: fit-content;
        padding-top: 3rem;
    }

    .section3Container span {
        font-size: 36px;
        width: 90%;
        line-height: 3rem;
    }

    .reflected_text::after {
        line-height: 2rem;
    }
    .section3Container h3{
        font-size: 18px;
        width: 90%;
        margin-left: 50%;
        transform:translateX(-50%);
    }
    .section3Container .imageContainer{
        width: 70%;
    }
}