.sideNavBarContainer{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top:0;
    transform: translateX(100%);
    transition: 0.3s ease;
    z-index: 999;
    padding-top: 1rem;
    background-color: #000;
}

.sideNavBarContainer.active{
    transform: translateX(0%);
}

.sideNavBarContainer .sideNavBarMenuContainer{
    width: 100%;
    min-height: 20vh;
    height: fit-content;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding:0 1rem;
}
.sideNavBarContainer .sideNavBarMenuContainer p{
    width:100%;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    text-align: left;
    margin:3% 0;
    font-family: 'FuturaNowLight', sans-serif;
}