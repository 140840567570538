@font-face {
  font-family: 'FuturaNowBold';
  src: url('./fonts/FuturaNow/FuturaNowHeadlineBold.ttf') format('woff2'),
    url('./fonts/FuturaNow/FuturaNowHeadlineBold.ttf') format('truetype');

  font-weight: normal;
  font-style: bold;
}

@font-face {
  font-family: 'FuturaNowReg';
  src: url('./fonts/FuturaNow/FuturaNowHeadline.ttf') format('woff2'),
    url('./fonts/FuturaNow/FuturaNowHeadline.ttf') format('truetype');

  font-weight: normal;
}

@font-face {
  font-family: 'FuturaNowLight';
  src: url('./fonts/FuturaNow/FuturaNowHeadlineExtraLight.ttf') format('woff2'),
    url('./fonts/FuturaNow/FuturaNowHeadlineExtraLight.ttf') format('truetype');

  font-weight: lighter;
}
*{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  border: 0;
  box-sizing: border-box;
  font-family: 'FuturaNowBold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #020202;
  color: #f9f6ee;
  letter-spacing: 0.1px;
  word-spacing: 1px;
  scroll-behavior: smooth;

}

span {
  font-family: 'FuturaNowBold', 'sans-serif';
}
p{
  font-family: 'FuturaNowLight', 'sans-serif';
}

html::-webkit-scrollbar{
  width:0.4vw;
}
html::-webkit-scrollbar-thumb{
  background-color:rgba(255,255,255,0.2);
  border-radius: 0.6rem;
}
html::-webkit-scrollbar-track{
  background-color:#0f0f0f;
}
input:focus{
  outline:none;
}
textarea:focus{
  outline:none;
}
